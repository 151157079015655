<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Attendee Arrival/Departure</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label-for="workshop-filter" label="Workshop(s):">
                                    <b-input-group size="sm">
                                        <b-select id="workshop-filter" v-model="workshops" multiple :select-size="8" :options="workshopOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="workshops = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label-for="session-filter" label="Session(s):">
                                    <b-input-group size="sm">
                                        <b-select id="session-filter" v-model="sessions" multiple :select-size="8" :options="sessionOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="sessions = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label-for="organization-type-filter" label="Organizations:">
                                    <b-input-group size="sm">
                                        <b-select id="organization-type-filter"
                                                  v-model="organizationType"
                                                  :options="organizationTypeOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="organizationType = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group label-for="organization-filter">
                                    <b-input-group size="sm">
                                        <b-select id="organization-filter"
                                                  v-model="organizations"
                                                  :options="organizationOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="organizations = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label-for="district-filter" label="District(s):">
                                    <b-input-group size="sm">
                                        <b-select id="district-filter" v-model="districts" multiple :select-size="6" :options="districtOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="districts = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-for="search-registration-status" label="Registration Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-registration-status" v-model="registrationStatus" :options="registrationStatusOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="registrationStatus = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="session-date-range" label="Session Start/End Dates:">
                                    <picker-of-dates type="date"
                                                     id="session-date-range"
                                                     v-model="sessionDateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="flight-date-time-range" label="Flight Arrival/Departure Date/Times:">
                                    <picker-of-dates type="datetime"
                                                     id="flight-date-time-range"
                                                     v-model="flightDateTimeRange"
                                                     format="MM/dd/yyyy h:mm a"
                                                     placeholder="Select Date/Time Range"
                                                     clearable range confirm/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="room-date-range" label="Room Check-In/Check-Out Dates:">
                                    <picker-of-dates type="date"
                                                     id="room-date-range"
                                                     v-model="roomDateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <b-form-group label-for="search-firstname" label="Attendee's First Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-firstname" placeholder="First Name" v-model="firstname"/>
                                        <b-input-group-addon>
                                            <b-button @click="firstname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col></b-col>
                            <b-col cols="3">
                                <b-form-group label-for="search-lastname" label="Attendee's Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-lastname" placeholder="Last Name" v-model="lastname"/>
                                        <b-input-group-addon>
                                            <b-button @click="lastname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col></b-col>
                            <b-col cols="3">
                                <b-form-group label-for="search-ubcid" label="Attendee's UBC ID:">
                                    <b-input-group size="sm">
                                        <ubc-id id="search-ubcid" v-model="ubcId" input-class="form-control form-control-sm"/>
                                        <b-input-group-addon>
                                            <b-button @click="ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col></b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="primary" @click="doSearch">Search</b-button>
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>

        <b-modal id="a-modal" v-model="modalShow" size="huge" scrollable title-html="<h3><strong>Attendee Arrival/Departure</strong></h3>">
            <b-row>
                <b-col cols="2" offset="9" class="text-right font-italic">
                    Records Found:
                </b-col>
                <b-col class="text-right">
                    {{ recordCount }}
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2" offset="9" class="text-right font-italic">
                    Active Instructors:
                </b-col>
                <b-col class="text-right">
                    {{ instructorCount }}
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2" offset="9" class="text-right font-italic">
                    Active Participants:
                </b-col>
                <b-col class="text-right">
                    {{ participantCount }}
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                </b-col>
                <b-col cols="8">
                    <b-form-group :label="'Records Per Page: ' + perPage">
                        <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                Page {{ currentPage }} of {{ pages }}
            </b-row>
            <b-row>
                <b-table small striped hover bordered no-local-sorting stacked="md" head-variant="dark"
                         ref="searchResultsTable"
                         :items="searchResults"
                         :fields="tableFields"
                         :sort-by.sync="sortKey"
                         :sort-desc.sync="sortDesc"
                         :per-page="perPage"
                         :current-page="currentPage">
                    <template v-if="activeUserIsAnAdministrator" v-slot:cell(comments)="data">
                        <template v-if="data.item.comments">
                            <div @click="showCommentForEdit(data.item)" style="cursor:pointer;">
                                {{ data.item.comments }}
                            </div>
                        </template>
                        <template v-else>
                            <b-badge variant="light" @click="showCommentForEdit(data.item)" style="cursor:pointer;">Add Comment</b-badge>
                        </template>
                    </template>
                </b-table>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                </b-col>
            </b-row>

            <template v-slot:modal-footer>
                <b-button-group size="sm">
                    <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                    <b-button variant="info" @click="modalShow = false">Close</b-button>
                </b-button-group>
            </template>
        </b-modal>

        <b-modal id="edit-comment-modal" centered title="Edit Comment" no-close-on-backdrop no-close-on-esc hide-header-close>
            <p>{{ selectedRow ? selectedRow.fullname : '' }}</p>
            <b-form-textarea
                id="edit-comment-textarea"
                v-model="tempComment"
                rows="6"
                max-rows="9"
                trim lazy
            ></b-form-textarea>
            <template v-slot:modal-footer>
                <b-button-group size="sm">
                    <b-button class="mr-2" variant="success" @click="saveComment">Save</b-button>
                    <b-button variant="info" @click="cancelComment">Cancel</b-button>
                </b-button-group>
            </template>
        </b-modal>

    </div>
</template>


<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import UbcId from '@/components/shared/UbcId.vue';
import _ from 'underscore';
import { date, mkDate } from '@/util/formatters';
import reportDao from '@/dao/report_dao';
import registrationDao from '@/dao/registration_dao';
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";


@Component({
    components: {
        PickerOfDates,
        UbcId
    }
})
export default class ArrivalDeparture extends Vue {

    workshops = [];
    sessions = [];
    organizationType = null;
    organizationOptions = [];
    organizations = [];
    districts = [];
    sessionDateRange = [null,null];
    flightDateTimeRange = [null,null];
    roomDateRange = [null,null];
    firstname = null;
    lastname = null;
    ubcId = null;
    registrationStatus = null;

    results = [];

    modalShow = false;

    recordCount = 0;
    instructorCount = 0;
    participantCount = 0;

    sortKey = null;
    sortDesc = false;
    currPage = 1;
    perPage = 10;

    selectedRow = null;
    tempComment = null;

    showCommentForEdit(rowItem) {
        this.selectedRow = rowItem;
        this.tempComment = rowItem.comments;
        this.$bvModal.show('edit-comment-modal');
    }

    async saveComment() {
        try {
            const response = await registrationDao.processRegistrationComment(JSON.stringify({
                id: this.selectedRow.registrationId,
                comments: this.tempComment
            }));
            if (response) {
                this.selectedRow.comments = this.tempComment;
                this.selectedRow = null;
                this.tempComment = null;
                this.$bvModal.hide('edit-comment-modal');
            } else {
                this.$bvModal.msgBoxOk('Save failed. If the problem persists, please contact the UBC Helpdesk.', {
                    size: 'sm',
                    buttonSize: 'sm'
                });
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    cancelComment() {
        this.selectedRow = null;
        this.tempComment = null;
        this.$bvModal.hide('edit-comment-modal');
    }

    get activeUserIsAnAdministrator() {
        return this.$store.getters['userSession/getUser'].isAnAdministrator();
    }

    get tableFields() {
        return [{
            key: 'arriving',
            label: 'Arriving',
            sortable: true,
            formatter: value => { return date(value, 'MM/dd/yyyy hh:mm a') }
        }, {
            key: 'departing',
            label: 'Departing',
            sortable: true,
            formatter: value => { return date(value, 'MM/dd/yyyy hh:mm a') }
        }, {
            key: 'vaccineDetails',
            label: 'COVID-19 Vaccine Verified',
            sortable: true
        }, {
            key: 'prerequisite',
            label: 'Prerequisite',
            sortable: true
        }, {
            key: 'comments',
            label: 'Comments',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'fullname',
            label: 'Attendee',
            sortable: true
        }, {
            key: 'location',
            label: 'Location',
            sortable: true
        }, {
            key: 'ubcid',
            label: 'UBC ID',
            sortable: true
        }, {
            key: 'type', // registration-type
            label: 'Type',
            sortable: true
        }, {
            key: 'workshopName',
            label: 'Workshop',
            sortable: true
        }, {
            key: 'startDate',
            label: 'Start Date',
            sortable: true,
            formatter: value => { return date(value, 'MM/dd/yyyy') }
        }, {
            key: 'endDate',
            label: 'End Date',
            sortable: true,
            formatter: value => { return date(value, 'MM/dd/yyyy') }
        }, {
            key: 'organization',
            label: 'Organization',
            sortable: true
        }, {
            key: 'district',
            label: 'District',
            sortable: true
        }, {
            key: 'roomRequest',
            label: 'Room Request',
            sortable: true
        }, {
            key: 'flightRequest',
            label: 'Flight Request',
            sortable: true
        }];
    }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        } else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);
        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;
            case page > this.pages:
                this.currPage = this.pages;
                break;
            default:
                this.currPage = page;
        }
    }

    get pages() {
        return Math.ceil(this.searchResults.length / this.perPage) || 1;
    }

    get searchResults() {
        const records = this.results;
        const sortedRows = _.chain(records)
            .sortBy((rec) => {
                switch (this.sortKey) {
                    case 'arriving': return rec.arriving || Date.parse("1999-12-31"); // when null, sort as earliest
                    case 'departing': return rec.departing || Date.parse("1999-12-31"); // when null, sort as earliest
                    case 'vaccineDetails': return rec.vaccineVerifiedDate || Date.parse("1999-12-31"); // when null, sort as earliest
                    case 'prerequisite': return rec.prerequisite || '';
                    case 'comments': return rec.comments || '';
                    case 'status': return rec.status || '';
                    case 'fullname': return rec.fullname || '';
                    case 'location': return rec.location;
                    case 'ubcid': return rec.ubcid || '';
                    case 'type': return rec.type || '';
                    case 'workshopName': return rec.workshopName || '';
                    case 'startDate': return rec.startDate || Date.parse('1999-12-31');
                    case 'endDate': return rec.endDate || Date.parse('1999-12-31');
                    case 'organization': return rec.organization || '';
                    case 'district': return rec.district || '';
                    case 'roomRequest': return rec.roomRequest || '';
                    case 'flightRequest': return rec.flightRequest || '';
                    default: return rec.fullname;
                }
            })
            .value();
        return this.sortDesc ? sortedRows.reverse() : sortedRows;
    }

    setTotals(recs) {
        this.recordCount = recs.length;
        const grouped = _.groupBy(recs, (rec) => {
            return rec.type;
        });
        if (grouped['Instructor']) { this.instructorCount = grouped['Instructor'].length; }
        if (grouped['Participant']) { this.participantCount = grouped['Participant'].length; }
    }

    get workshopOptions() {
        return _.map(this.$store.getters['common/workshops'], (ws) => {
            return {
                value: ws.id,
                text: ws.value
            }
        });
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/workshopSessionsWorkshopInstanceID'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    @Watch('workshops')
    async workshopsSelected(workshopIds) {
        this.sessions = [];
        await this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', workshopIds)
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    get organizationTypeOptions() {
        return [{
            value: 'trust-fund',
            text: 'Trust Fund(s)'
        }, {
            value: 'training-center',
            text: 'Training Center(s)'
        }, {
            value: 'council',
            text: 'Council(s)'
        }, {
            value: 'affiliate',
            text: 'Local(s)'
        }, {
            value: 'sct-contractor',
            text: 'Contractor(s)'
        }, {
            value: 'other',
            text: 'Other(s)'
        }];
    }

    @Watch('organizationType')
    organizationTypeSelected(orgType) {
        this.organizations = [];
        this.organizationOptions = [];
        switch (orgType) {
            case "trust-fund":
                this.loadTrustFunds();
                break;
            case "training-center":
                this.loadTrainingCenters();
                break;
            case "council":
                this.loadCouncils();
                break;
            case "affiliate":
                this.loadLocals();
                break;
            case "sct-contractor":
                this.loadContractors();
                break;
            case "other":
                this.loadOrganizations();
                break;
            default:
                // nuthin'
                break;
        }
    }

    loadTrainingCenters() {
        this.organizationOptions = _.map(this.$store.getters['common/trainingCenters'], (tc) => {
            return {
                value: tc.id,
                text: tc.value
            };
        });
    }

    loadTrustFunds() {
        this.organizationOptions = _.map(this.$store.getters['common/trustFunds'], (tf) => {
            return {
                value: tf.id,
                text: tf.value
            };
        });
    }

    loadCouncils() {
        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
        this.organizationOptions = _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.displayId,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    loadLocals() {
        const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf("%s - %s", l.districtName, l.councilName));
        this.organizationOptions = _.map(locals, (ls, districtCouncil) => {
            return {
                label: districtCouncil,
                options: _.map(ls, (local) => {
                    return {
                        value: local.displayId,
                        text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                    };
                })
            };
        });
    }

    loadContractors() {
        this.organizationOptions = _.map(this.$store.getters['organizations/getContractors'], (c) => {
            return {
                value: c.id,
                text: c.name
            };
        });
    }

    loadOrganizations() {
        this.organizationOptions = _.map(this.$store.getters['organizations/getOrganizations'], (c) => {
            return {
                value: c.id,
                text: c.name
            };
        });
    }

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.id,
                text: d.name
            };
        });
    }

    get registrationStatusOptions() {
        return [
            { value: "active",   text: "Active" },
            { value: "canceled", text: "Canceled" }
        ];
    }

    clearFilters() {
        this.workshops = [];
        this.organizationType = null;
        this.districts = [];
        this.sessionDateRange = [null,null];
        this.flightDateTimeRange = [null,null];
        this.roomDateRange = [null,null];
        this.firstname = null;
        this.lastname = null;
        this.ubcId = null;
        this.registrationStatus = null;
    }

    async doSearch() {
        if (!this.isReady()) {
            return;
        }
        try {
            const records = await reportDao.getArrivalDeparture(JSON.stringify({
                workshopIds: this.workshops,
                sessionIds: this.sessions,
                organizationType: this.organizationType,
                organizationIds: this.organizations,
                districtIds: this.districts,
                sessionDateRange: this.sessionDateRange,
                flightDateTimeRange: [
                    !this.flightDateTimeRange[0] ? null : new Date(this.flightDateTimeRange[0].getTime() - (this.flightDateTimeRange[0].getTimezoneOffset() * 60000)),
                    !this.flightDateTimeRange[1] ? null : new Date(this.flightDateTimeRange[1].getTime() - (this.flightDateTimeRange[1].getTimezoneOffset() * 60000))
                ],
                roomDateRange: this.roomDateRange,
                firstname: this.firstname,
                lastname: this.lastname,
                ubcId: this.ubcId,
                registrationStatus: this.registrationStatus
            }));
            this.results = records;
            this.setTotals(records);
            this.modalShow = true;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getCsv() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/arrival-departure', JSON.stringify({
                workshopIds: this.workshops,
                sessionIds: this.sessions,
                organizationType: this.organizationType,
                organizationIds: this.organizations,
                districtIds: this.districts,
                sessionDateRange: this.sessionDateRange,
                flightDateTimeRange: this.flightDateTimeRange,
                roomDateRange: this.roomDateRange,
                firstname: this.firstname,
                lastname: this.lastname,
                ubcId: this.ubcId,
                registrationStatus: this.registrationStatus
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        const nuthinIsSelected =
                !this.sessionDateRange[0] && !this.flightDateTimeRange[0] && !this.roomDateRange[0]
                && !this.workshops[0] && !this.sessions[0] && !this.organizations[0] && !this.districts[0]
                && !this.firstname && !this.lastname && !this.ubcId && !this.registrationStatus;

        if (nuthinIsSelected) {
            this.$bvModal.msgBoxOk('Please enter at least one search criteria.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    destroyed() {
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Attendee Arrival/Departure', null, true));
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
        _.each([
            'common/loadWorkshops',
            'common/loadDistricts',
            'common/loadTrustFunds',
            'common/loadTrainingCenters',
            'common/loadCouncils',
            'common/loadLocals',
            'organizations/loadContractors',
            'organizations/loadOrganizations'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        // clear out 'count' fields when a-model is closed (search results)
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId === "a-modal") {
                this.recordCount = 0;
                this.instructorCount = 0;
                this.participantCount = 0;
            }
        });
    }
}
</script>


<style>
@media (min-width: 992px) {
    .modal-huge {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        overflow: auto;
    }
}
</style>